body {
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EDF3FF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body, #root {
  height: 100%;
  overflow-x: hidden;
}

.main {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
}

.header {
  padding: 32px 80px 36px 370px;
  flex-shrink: 0;
}

.heading {
  color: #05060F;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */

  margin-bottom: 8px;
}

.description {
  color: #222426;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;

  max-width: 610px;
}

.table-container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 32px 0 0;
  flex-shrink: 0;
  flex-grow: 1;
}

.scroll-container {
  width: 100%;
  overflow-x: auto;
  padding: 0 80px 32px;
}

.table-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px);
  padding: 0 80px 18px;
  flex-wrap: wrap;
  margin-left: auto;
}

.table-heading {
  color: #1E1E1F;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 177.778% */
  padding-right: 16px;
}

.sort-by {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 26px;

  margin: 0 auto;
}

.sort-by__heading {
  color: #05060F;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 266.667% */
  width: fit-content;
}

.sort-by__button {
  background: none;
  border: none;
  box-shadow: none;

  padding: 0;
  margin: 0;

  color: #05060F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;

  text-transform: capitalize;
}

.sort-by__button_active {
  font-weight: 600;
}

.arrow-up {
  transform: rotate(180deg);
}

.wallet-balances {
  display: grid;
  grid-template-columns: repeat(5, minmax(180px, 1fr));
  margin-left: 60px;
}

.wallet-balances__loader {
  left: -4px;
}

.wallet-name {
  color: #676B73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}

.wallet-balance {
  color: #676B73;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}

.wallet-balance-card {
  padding: 22px 8px;
  border-bottom: 1px solid #EDF3FF;
  border-top: 1px solid #EDF3FF;
}

.wallet-balance-card:first-child {
  padding-left: 16px;
}

.wallet-balance-card:last-child {
  padding-right: 16px;
}

.wallet-balance-card:first-child .wallet-name {
    color: #0E0E0E;
}

.wallet-balance-card:first-child .wallet-balance {
    color: #191919;
}


.table {
  width: 100%;
}

.table__row {
  display: grid;
  grid-template-columns: 60px repeat(5, minmax(180px, 1fr));
}

.table__cell {
  padding: 8px 8px;
  border-bottom: 1px solid #EDF3FF;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  gap: 24px;
}

.table__cell-data {
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
}

.table__cell_info {
  border: none;
  /*padding-right: 12px;*/
  width: 60px;
  padding: 8px 16px 8px 0;
}

.table__cell:nth-child(2) {
  padding-left: 16px;
}

.table__cell:last-child {
  padding-right: 16px;
}

.cell-title {
  color: #676B73;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
}

.tx-link {
  color: #7DA3F9;
  /* Caption 2/400 - Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;

  text-decoration: none;
}

.tx-amount {
  color: #0E0E0E;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;

  white-space: nowrap;
}

.side-menu-container {
  left: 20px;
}

.side-menu {
  border-radius: 16px !important;
}

.comment-button {
  width: 32px;
  height: 32px;
  padding: 8px;
  background: #fff;

  border-radius: 30px;
  border: none;
  cursor: pointer;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: background .2s ease-in-out;
}

.comment-button:hover {
  background: #EDF3FF;
}

.comment-button_active {
  background: #EDF3FF;
}

.comment-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: fit-content;

  padding: 16px 20px;

  border-radius: 24px 24px 0 0;
  background: #EDF3FF;
}

.comment-modal__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin: 0 auto;

  max-width: 800px;
}

.comment-modal__title {
  color: #191919;
  font-size: 12px;
  font-style: normal;
  /*TODO: import font weight*/
  /*font-weight: 500;*/
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
  margin-bottom: 4px;
}

.comment-modal__text {
  color:  #51545A;
  /* Caption 2/400 - Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
  margin-bottom: 16px;

  & a:visited, & a:link {
    color: #51545A;
  }
}

.comment-modal__tx-hash {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px;
}

.comment-modal__tx-hash-title {
  margin-right: 24px;
}

.comment-modal__tx-hash-link:link, .comment-modal__tx-hash-link:visited {
  color: #000;
  text-decoration: none;
}

.comment-modal__close-icon {
  cursor: pointer;
  flex-shrink: 0;
}

@media screen and (max-width: 1023px) {
  .table-container {
    padding: 20px 0 0;
  }

  .table-header {
    padding: 0 24px 18px;
  }

  .scroll-container {
    padding: 0 24px 20px;
  }

  .header {
    padding: 100px 24px 35px;
  }
}

@media screen and (max-width: 768px) {
  .table-heading {
    width: 100%;
  }

  .sort-by {
    margin: 0;
  }

  .comment-modal {
    width: calc(100vw - 12px);
    left: 6px;
    bottom: 6px;
    padding: 12px 16px;
    border-radius: 13px;
    border: 1px solid #C2C5CC;
    background: #FFF;
  }

  .comment-modal__container {
    align-items: flex-start;
  }

  .comment-modal__tx-hash-title {
    color: #676B73;
  }

  .comment-modal__tx-hash {
    color: #7DA3F9;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.12px;
  }

  .comment-modal__tx-hash-link:link, .comment-modal__tx-hash-link:visited {
    color: #7DA3F9;
  }

  .comment-modal__close-icon > rect {
    fill: #fff;
  }
}

@media screen and (max-width: 500px) {
 .table-header {
   margin-left: 0;
   padding: 0;
   width: calc(100% - 30px);
 }

  .header {
    padding-left: 12px;
  }

  .sort-by {
    gap: 8px;
  }

  .scroll-container {
    margin-top: 20px;
  }
}
@media screen and (max-width: 470px) {
  .side-menu {
    border-radius: unset;
  }
}


.inline-loader {
  display: inline-block;
  position: relative;
  width: 2.5rem;
  height: 0.375rem;
}

.inline-loader div {
  position: absolute;
  top: 0;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: #A1A6B2;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.inline-loader div:nth-child(1) {
  left: 0.25rem;
  animation: inline-loader1 0.6s infinite;
}

.inline-loader div:nth-child(2) {
  left: 0.25rem;
  animation: inline-loader2 0.6s infinite;
}

.inline-loader div:nth-child(3) {
  left: 1rem;
  animation: inline-loader2 0.6s infinite;
}

.inline-loader div:nth-child(4) {
  left: 1.75rem;
  animation: inline-loader3 0.6s infinite;
}

@keyframes inline-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes inline-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes inline-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0.75rem, 0);
  }
}

@media screen and (max-width: 768px) {
  .tx-amount_timestamp {
    width: 100px;
    text-wrap: inherit;
  }
}

.filter-wrapper {
  position: relative;
  width: 140px;
  display: flex;
  justify-content: flex-end;
  height: 43px;
  align-items: center;
}

.filter-wrapper:hover .filter-dropdown {
  display: flex;
}

.filter-button {
  height: 30px;
  color: var(--gray-800, #222);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  border-radius: 20px;
  border: 1px solid var(--alpha-black-10, rgba(47, 43, 67, 0.10));
  background: var(--base-white, #FFF);
  padding: 4px 8px;
  cursor: pointer;
}

.filter-dropdown {
  display: none;
  position: absolute;
  padding: 16px;
  width: 150px;
  border-radius: 16px;
  border: 1px solid var(--neutral-100, #E6E6E6);
  background: #FFF;
  box-shadow: 0px 6px 12px 0px rgba(47, 43, 67, 0.10);
  flex-direction: column;
  gap: 16px 0;
  right: 0;
  top: 43px;
}

.filter-dropdown__item {
  color: var(--neutral-400, #676B73);
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  cursor: pointer;
}

.arrow-green {
  color: var(--teal-400-success, #159F80);
}

